<template>
  <div class="direct-page">
    <div class="title">代领餐</div>
    <van-form ref='form'>
      <van-field label="代领人" v-model="username" disabled name="username"></van-field>
      <van-radio-group v-model="type">
        <van-cell-group>
          <van-cell title="午餐" clickable @click="type = 'lunch'" style="text-align:left">
            <van-radio slot="right-icon" name="lunch" />
          </van-cell>
          <van-cell title="晚餐" clickable @click="type = 'dinner'" style="text-align:left">
            <van-radio slot="right-icon" name="dinner" />
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-field label="备注" v-model="note" placeholder="请输入备注" :rules="noteRules"></van-field>
      <div class="input_item" v-for="(item,index) in names" :key="index">
        <van-field 
          :label="'姓名'+(index+1)" 
          v-model="item.text"
          :data-index="index"
          :rules="noteName"
          placeholder="请输入姓名"  />
          <van-button size="small" icon="close" type="danger" plain :data-index="index" v-if="index !=0" @click="handleDelete(index)"></van-button>
      </div>
      <van-button size="small" icon="plus" type="info" @click="onAdd" v-if="names.length<5">添加姓名</van-button>
      <van-button type="info" block style="margin-top:10px;" :disabled="isDisabled" @click="submit">确认代领</van-button>
  </van-form>
  </div>
</template>
<script>
//import * as dd from 'dingtalk-jsapi';
import Vue from 'vue';
import { Form,Field,RadioGroup, Icon,Radio,Cell,CellGroup,Dialog,Toast} from 'vant';

import { getOthersLead } from '@/api'

Vue.use(Form);
Vue.use(Field)
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Cell).use(CellGroup);
export default {
  name:'giveHand',
  data(){
    return {
      username:'',
      type:'lunch',
      names:[
        {
          text:''
        }
      ],
      note:'',
      typeList:[
        { text: '午餐', value: 'lunch' },
        { text: '晚餐', value: 'dinner' }
      ],
      noteRules:[{
        required: true,
        message: '',
        trigger: 'onBlur'
      }],
      noteName:[
        {
          required: true,
          message: '',
          trigger: 'onBlur'
        }
      ],
      isDisabled:false
    }
  },
  mounted(){
    this.init()
  },
  methods:{
    init(){
      this.username = this.$route.params.name
    },
    onAdd(){
      let obj = { text: '' }
      if(this.names.length<5){
        this.names.push(obj);
      }
    },
    handleDelete(index){
      this.names.splice(index,1)
    },
    submit(){
      this.isDisabled = true
      this.$refs.form.validate().then(async () => {
        let namesList = []
        this.names.map(item =>{
          namesList.push(item.text)
        })
        let params = {
          user:this.username,
          type:this.type,
          note:this.note,
          names:namesList
        }
        let res = await getOthersLead(params)
        if(res.code == '200') {
          this.isDisabled = false
          Dialog.alert({
            confirmButtonText: '关闭',
            message: res.result+ '\n 备注：此弹窗需给打饭工作人员查看，\n确认后再关闭！'
          }).then(() => {
            this.$router.push({
              name: "Home",
            });
          });
        }else{
          this.isDisabled = false
          Toast.fail('提交失败，请重试！');
        }
      }).catch(() => {
        this.isDisabled = false
        this.$toast.fail('必填项不能为空！')
      })
    }
  },
}
</script>
<style scoped>
  .direct-page {
    padding: 10px 20px;
  }
  .title {
    margin: 10px 0 20px 0;
    color: #40a9ff;
    font-size: 22px;
  }
  .input_item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close-icon {
    font-size: 28px;
    color: #ff5f4e;
  }
</style>